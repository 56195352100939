import {
  DashboardOutlined,
  EuroCircleOutlined,
  LinkOutlined,
  DeploymentUnitOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'sidenav.home',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'reports',
    path: `${APP_PREFIX_PATH}/reports`,
    title: 'sidenav.reports',
    icon: '',
    breadcrumb: false,
    submenu: [
      {
        key: 'reports-commissions',
        path: `${APP_PREFIX_PATH}/reports/commissions`,
        title: 'sidenav.reports.commissions',
        icon: EuroCircleOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'reports-clicks',
        path: `${APP_PREFIX_PATH}/reports/clicks`,
        title: 'sidenav.reports.clicks',
        icon: LinkOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'reports-networks',
        path: '',
        title: 'sidenav.reports.networks',
        icon: DeploymentUnitOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'reports-networks-commissions',
            path: '',
            title: 'sidenav.reports.networks.commissions',
            icon: '',
            breadcrumb: true,
            submenu: []
          }
        ]
      }
    ]
  },
  {
    key: 'accounting',
    path: `${APP_PREFIX_PATH}/accounting`,
    title: 'sidenav.accounting',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'accounting-credits',
        path: `${APP_PREFIX_PATH}/credits`,
        title: 'sidenav.accounting.credits',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'accounting-externalCredits',
        path: `${APP_PREFIX_PATH}/credits`,
        title: 'sidenav.accounting.externalCredits',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: []
      }
    ]
  },
  {
    key: 'publisher',
    path: '',
    title: 'sidenav.publisher',
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'publisher-adspaces',
        path: '',
        title: 'sidenav.publisher.adspaces',
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'publisher-supportTickets',
        path: '',
        title: 'sidenav.publisher.supportTickets',
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'publisher-api',
        path: '',
        title: 'sidenav.publisher.api',
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: []
      },
    ]
  },
  {
    key: 'advertiser',
    path: '',
    title: 'sidenav.advertiser',
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'advertiser-active',
        path: '',
        title: 'sidenav.advertiser.active',
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: []
      },]
  },
  {
    key: 'publicArea',
    path: '',
    title: 'sidenav.publicArea',
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'publicArea-blog',
        path: '',
        title: 'sidenav.publicArea.blog',
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'publicArea-events',
        path: '',
        title: 'sidenav.publicArea.events',
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: []
      },
    ]
  },
  {
    key: 'system',
    path: '',
    title: 'sidenav.system',
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'system-processList',
        path: '',
        title: 'sidenav.system.processList',
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'system-jobs',
        path: '',
        title: 'sidenav.system.jobs',
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: []
      },
    ]
  },
]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
